import React from "react";
import { RoundedButton } from "shared/components/common/Button";
import { Actions } from "shared/components/Link";
import showToast from "shared/components/Util/Feedback/Toast";
import { PaymentAttempt } from "shared/models/Payment";
import Session from "shared/models/Sessions";
import useScript from "../../hooks/useScript";
import FWButton from "./PayButton";

const RazorPayComp = ({
  config,
  amount,
  pay_option_id,
  batch_id,
  text,
  big,
  disabled = false,
}) => {
  useScript("https://checkout.razorpay.com/v1/checkout.js");

  const pay = () => {
    let userData = Session.userData.user;
    showToast.toast("Please Wait ...", "info");
    PaymentAttempt.create(pay_option_id, amount, batch_id)
      .then((pay_attempt) => {
        if (!pay_attempt.txnRef) {
          // Show error if null order id
          return showToast.toast("Payment Failed. Try Again", "error");
        }
        const request = {
          key: config.key,
          currency: config.currency,
          name: config.name,
          order_id: pay_attempt.txnRef,
          amount: pay_attempt.amountInCents,
          unit: "paisa",
          prefill: {
            name: userData.name,
            email: userData.email,
            contact: userData.mobile,
          },
        };
        const rzpl = new window.Razorpay({
          ...request,
          handler: (response) => {
            console.log("pay -> response", response);
            let status = response.razorpay_payment_id ? "SUCCESS" : "FAILURE";
            pay_attempt
              .pay({
                request,
                response,
                status,
              })
              .then((res) => {
                let new_attempt = res.body().data().result;
                new_attempt.status === "success"
                  ? showToast.toast("Payment Successful", "success")
                  : showToast.toast("Payment Failed", "error");
                console.log("pay -> new_attempt", new_attempt);
                Actions.push({
                  screen: "payment_status",
                  page: `/payment_status/${batch_id}/${new_attempt.id}`,
                });
              });
          },
        });
        rzpl.open();
      })
      .catch((e) => {
        console.log("pay -> e", e);
        showToast.toast("Failed To Pay. Try Again Later", "error");
      });
  };

  const defaultText = "Credit Card / Netbanking / Debit Card";
  const Button = big ? FWButton : RoundedButton;

  return (
    <Button disabled={disabled} text={text || defaultText} onPress={pay} />
  );
};

export default RazorPayComp;
